import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import * as configurationApi from "@/api/configuration/api"

export default [
    {
        path: "/system-setting",
        name: "system-setting-wrapper",
        meta: {
            isShow: false,
            position: "",
            name: "系統設定",
        },
        component: DefaultLayout,
        children: [
            {
                path: "",
                component: () =>
                    import("@/pages/CommonGroup/SystemSettingPage.vue"),
                name: "system-setting",
                meta: {
                    requiresAuth: true,
                },
                props: true,
                beforeEnter: async (to) => {
                    try {
                        const response =
                            await configurationApi.getConfiguration()

                        to.params.initialConfigurationResponse = response

                        return true
                    } catch (error) {
                        return false
                    }
                },
            },
        ],
    },
]
