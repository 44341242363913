import { instance } from "../axios"

/**
 * 取得 uploads 列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUploadsFiles(config = {}) {
    return instance.request({
        url: "/datahubs/uploads/",
        method: "get",
        ...config,
    })
}

/**
 * 取得 上傳類型列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUploadsFilesType(config = {}) {
    return instance.request({
        url: "/datahubs/uploads/types/",
        method: "get",
        ...config,
    })
}

/**
 * 取得 上傳狀態列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUploadsFilesStatus(config = {}) {
    return instance.request({
        url: "/datahubs/uploads/status/",
        method: "get",
        ...config,
    })
}

/**
 * 上傳 upload 檔案
 * @param {object} config config
 * @returns {Promise} API response
 */
export function uploadUploadFile(config = {}) {
    return instance.request({
        url: `/datahubs/uploads/`,
        method: "post",
        ...config,
    })
}

/**
 * 下載 上傳類型 範本檔案
 * @param {string} uploadType uploadType
 * @param {object} config config
 * @returns {Promise} API response
 */
export function downloadSampleFile(uploadType, config = {}) {
    return instance.request({
        url: `/datahubs/uploads/${uploadType}/template/`,
        method: "get",
        ...config,
    })
}

/**
 * 下載 uploads 檔案
 * @param {string} uuid id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function downloadUploadFile(uuid, config = {}) {
    return instance.request({
        url: `/datahubs/uploads/${uuid}/export/`,
        method: "get",
        ...config,
    })
}

/**
 * 刪除 uploads 檔案
 * @param {string} uuid id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function deleteUploadFile(uuid, config = {}) {
    return instance.request({
        url: `/datahubs/uploads/${uuid}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 取得 uploads 資訊
 * @param {string} uuid id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUploadFileInfo(uuid, config = {}) {
    return instance.request({
        url: `/datahubs/uploads/${uuid}/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得 uploads 資料列表
 * @param {string} uuid id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUploadFileList(uuid, config = {}) {
    return instance.request({
        url: `/datahubs/uploads/${uuid}/list/`,
        method: "get",
        ...config,
    })
}
