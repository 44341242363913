<script setup>
    import { useRouter, useRoute } from "vue-router"
    import { useUserStore } from "@/stores/user"
    import { computed, ref, provide } from "vue"

    import checkPermissions from "@/utils/checkPermissions"

    const router = useRouter()
    const route = useRoute()
    const userStore = useUserStore()
    const isCollapse = ref(false)

    const { hasPermissionTopNavbarItem, currentTopNavbarItem } =
        useTopNavbarItem(router, userStore)

    const { firstSidebarItems } = useFirstSidebarItems(
        hasPermissionTopNavbarItem,
        userStore
    )
    const { sideNavbarItems, currenSideNavbarItem } = useSideNavbarItem(
        currentTopNavbarItem,
        userStore
    )

    const currentContainerPadding = computed(() => {
        if (sideNavbarItems.value.length) {
            return isCollapse.value ? "58px 0px 0px 62px" : "58px 0px 0px 199px"
        }
        return "58px 16px 0px"
    })

    const sidebarWidth = computed(() => {
        if (sideNavbarItems.value.length)
            return isCollapse.value ? "62px" : "199px"
        return "0px"
    })

    provide("sidebarWidth", sidebarWidth)

    /**
     * 顯示具備權限的頂邊欄
     * @param {RouterInstance} router - Vue Router 的實例。
     * @param {Reactive} userStore - 使用者存儲的實例。
     * @returns {object} - 包含頂邊導航欄項目和當前頂邊導航欄項目的物件。
     * @property {Ref<Array>} hasPermissionTopNavbarItem - 頂邊導航欄項目的計算屬性。
     * @property {Ref<object>} currentTopNavbarItem - 當前頂邊導航欄項目的計算屬性。
     */
    function useTopNavbarItem(router, userStore) {
        const hasPermissionTopNavbarItem = computed(() => {
            return router.options.routes
                .filter((item) => item.meta?.isShow)
                .filter((item) => {
                    if (item.meta?.permissions) {
                        return checkPermissions(
                            item.meta.permissions,
                            userStore
                        )
                    } else {
                        const permissions = item.children
                            .filter((item) => item.meta?.permissions)
                            .map((item) => item.meta?.permissions)
                        return checkPermissions(permissions.flat(), userStore)
                    }
                })
                .slice()
                .sort((a, b) => a.meta.index - b.meta.index)
        })

        const currentTopNavbarItem = computed(() => {
            return (
                hasPermissionTopNavbarItem.value.filter((item) => {
                    return (
                        item.meta?.menuGroup ===
                        router.currentRoute.value.meta?.menuGroup
                    )
                })[0] || {}
            )
        })

        return { hasPermissionTopNavbarItem, currentTopNavbarItem }
    }

    /**
     * 現有頂邊欄位中，可去往的第一個側邊欄
     * @param {Ref<Array>} hasPermissionTopNavbarItem - 可顯示的頂部導航欄項目的計算屬性。
     * @param {Reactive} userStore - 使用者存儲的實例。
     * @returns {object} - object
     * @property {Ref<Array>} firstSidebarItems - 所有側邊第一個導航欄項目。
     */
    function useFirstSidebarItems(hasPermissionTopNavbarItem, userStore) {
        const firstSidebarItems = computed(() => {
            return hasPermissionTopNavbarItem.value.map((topItem) => {
                return topItem?.children
                    ?.filter((item) => item.meta?.isSubShow)
                    .filter((item) => {
                        return checkPermissions(
                            item.meta?.permissions,
                            userStore
                        )
                    })
                    .slice()
                    .sort((a, b) => a.meta.subIndex - b.meta.subIndex)
            })
        })

        return {
            firstSidebarItems,
        }
    }

    /**
     * 顯示具備權限的側邊欄
     * @param {Ref<Array>} currentTopNavbarItem - 當前頂部導航欄項目的計算屬性。
     * @param {Reactive} userStore - 使用者存儲的實例。
     * @returns {object} - 包含側邊導航欄項目和當前側邊導航欄項目的物件。
     * @property {Ref<Array>} sideNavbarItems - 側邊導航欄項目的計算屬性。
     * @property {Ref<object>} currenSideNavbarItem - 當前側邊導航欄項目的計算屬性。
     */
    function useSideNavbarItem(currentTopNavbarItem, userStore) {
        const sideNavbarItems = computed(() => {
            let currentMenu = currentTopNavbarItem.value

            // 無當前頂部導航欄的項目使用判斷獲得側邊導航欄項目
            const menuGroups = [
                "account-manage",
                "notification-center",
                "platform-settings",
            ]
            if (menuGroups.includes(route.meta.menuGroup)) {
                currentMenu = router.options.routes.filter(
                    (item) => item.meta?.menuGroup === route.meta.menuGroup
                )[0]
            }

            return (
                currentMenu?.children
                    ?.filter((item) => item.meta?.isSubShow)
                    .filter((item) => {
                        return checkPermissions(
                            item.meta?.permissions,
                            userStore
                        )
                    })
                    .slice()
                    .sort((a, b) => a.meta.subIndex - b.meta.subIndex) || []
            )
        })

        const currenSideNavbarItem = computed(() => {
            return route
        })

        return { sideNavbarItems, currenSideNavbarItem }
    }
</script>

<template>
    <el-container>
        <el-header style="padding: 0" class="default-header">
            <base-menu
                :items="hasPermissionTopNavbarItem"
                :current-item="currentTopNavbarItem"
                :subitems="firstSidebarItems"
                :user="userStore.userInfo"
            />
        </el-header>
        <el-container>
            <el-aside v-if="sideNavbarItems.length" class="default-aside !z-10">
                <base-navbar
                    v-model:isCollapse="isCollapse"
                    :items="sideNavbarItems"
                    :current-item="currenSideNavbarItem"
                />
            </el-aside>
            <el-main style="background-color: #fafcff" class="default-main">
                <router-view />
            </el-main>
        </el-container>
    </el-container>
</template>

<style scoped lang="scss">
    .default-header {
        position: fixed;
        z-index: 99;
        left: 0;
        right: 0;
    }

    .default-aside {
        position: fixed;
        z-index: 0;
        top: 60px;
        max-width: 200px;
        transition: all 0.3s ease;
    }

    .default-main {
        padding: v-bind("currentContainerPadding");
        min-height: 100vh;
        transition: padding-left 0.3s ease;
    }
</style>
