import { instance } from "../axios"

/**
 * 取得 users
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUsers(config = {}) {
    return instance.request({
        url: "/accounts/users/",
        method: "get",
        ...config,
    })
}

/**
 * 取得 users 列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUserOptions(config = {}) {
    return instance.request({
        url: "/accounts/users-options/",
        method: "get",
        ...config,
    })
}

/**
 * 取得單個 user
 * @param {string} userId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUser(userId, config = {}) {
    return instance.request({
        url: `/accounts/users/${userId}/`,
        method: "get",
        ...config,
    })
}

/**
 * 新增 user
 * @param {object} config config
 * @returns {Promise} API response
 */
export function createUser(config = {}) {
    return instance.request({
        url: "/accounts/users/",
        method: "post",
        ...config,
    })
}

/**
 * 更新 user
 * @param {string} userId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateUser(userId, config = {}) {
    return instance.request({
        url: `/accounts/users/${userId}/`,
        method: "patch",
        data: config.data,
        ...config,
    })
}

/**
 * 啟用 user
 * @param {string} userId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function setUserActive(userId, config = {}) {
    return instance.request({
        url: `/accounts/users/${userId}/activate/`,
        method: "post",
        ...config,
    })
}

/**
 * 停用 user
 * @param {string} userId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function setUserInactive(userId, config = {}) {
    return instance.request({
        url: `/accounts/users/${userId}/inactivate/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得 user 權限
 * @param {string} userId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getUserPermissions(userId, config = {}) {
    return instance.request({
        url: `/accounts/users/${userId}/permissions/`,
        method: "get",
        ...config,
    })
}

/**
 * 更新 user 權限
 * @param {string} userId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateUserPermissions(userId, config = {}) {
    return instance.request({
        url: `/accounts/users/${userId}/permissions/`,
        method: "patch",
        ...config,
    })
}

/**
 * 發送 user 啟用信件
 * @param {string} userId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function sendUserEmail(userId, config = {}) {
    return instance.request({
        url: `/accounts/users/${userId}/resend-invite/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得操作歷程列表
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getOperationHistory(config = {}) {
    return instance.request({
        url: `/accounts/users/operation-logs/`,
        method: "get",
        ...config,
    })
}
