export default {
    // 通用錯誤
    10001: "填入的數值有誤，請確認後重新輸入",
    10002: "意外錯誤，請確認編輯的內容是否存在",
    10003: "已存在同樣的內容，請確認後重新輸入",
    10004: "登入錯誤超過 5 次，請十分鐘後再登入",
    10005: "不存在此帳號、或密碼有誤，請確認帳號密碼後重新輸入",
    10007: "已具備相同名稱，請重新更改名稱",
    10008: "已經有發送試推播訊息，請等待 2 分鐘後再重新進行推播",
    10009: "此驗證碼已過期，請點選刷新按鈕後重新輸入",
    10010: "驗證碼輸入錯誤，請重新輸入",
    10011: "資料庫請求異常，如果問題持續發生，請聯繫管理員",
    10012: "必填欄位未完成，無法送交審核",
    10015: "用戶有建立目前為排程中的行銷活動，不可編輯",
    // 旅程畫布錯誤
    20001: "畫布中不存在任何節點",
    20002: "沒有正確包含起始節點",
    20003: "節點未正確設置",
    20004: "節點連接錯誤",
    20005: "一個節點的右側端口，只能連結一個節點左側端口",
    20006: "一個節點左側端口，只能連結一個節點的右側端口",
    20007: "只能包含一個起始節點，請刪除多餘起始節點。並檢查節點間的連結方式",
    20008: "節點間的連結方式錯誤，請重新確認節點連結",
    20009: "尚無劇本分析資料",
    // 受眾計算錯誤
    30001: "在受眾計算過程中出現了一些錯誤",
    30002: "受眾計算已經完成",
    30003: "受眾計算已經停止",
    30004: "受眾計算仍在進行中",
    30005: "鎖定的用戶在計算完成之前無法保存",
    30006: "受眾計算尚未開始",
    30007: "無效的抽樣比例",
    30008: "不可停用，有子分群在使用中",
    // EDM 錯誤
    50001: "郵件寄送失敗，請確認所選信箱是否通過供應商驗證",
}
