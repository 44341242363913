import { instance } from "../axios"

//============================ sms ============================//

/**
 * 取得 sms 素材各種狀態的數量
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getSmsStatusCount(config = {}) {
    return instance.request({
        url: "/assets/sms/counts/",
        method: "get",
        ...config,
    })
}

/**
 * 取得 sms
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getSmsList(config = {}) {
    return instance.request({ url: "/assets/sms/", method: "get", ...config })
}

/**
 * 取得單個 sms
 * @param {string} smsId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getSms(smsId, config = {}) {
    return instance.request({
        url: `/assets/sms/${smsId}/`,
        method: "get",
        ...config,
    })
}

/**
 * 新增 sms
 * @param {object} config config
 * @returns {Promise} API response
 */
export function createSms(config = {}) {
    return instance.request({ url: "/assets/sms/", method: "post", ...config })
}

/**
 * 編輯 sms
 * @param {string} smsId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateSms(smsId, config = {}) {
    return instance.request({
        url: `/assets/sms/${smsId}/`,
        method: "put",
        ...config,
    })
}

/**
 * 複製 sms
 * @param {string} smsId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function copySms(smsId, config = {}) {
    return instance.request({
        url: `/assets/sms/${smsId}/copy/`,
        method: "post",
        ...config,
    })
}

/**
 * 停用 sms
 * @param {string} smsId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function disableSms(smsId, config = {}) {
    return instance.request({
        url: `/assets/sms/${smsId}/disable/`,
        method: "put",
        ...config,
    })
}

/**
 * 刪除 sms
 * @param {string} smsId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function deleteSms(smsId, config = {}) {
    return instance.request({
        url: `/assets/sms/${smsId}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 送審 sms
 * @param {string} smsId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function submitSms(smsId, config = {}) {
    return instance.request({
        url: `/assets/sms/${smsId}/submit/`,
        method: "post",
        ...config,
    })
}

/**
 * 審核 sms
 * @param {string} smsId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function reviewSms(smsId, config = {}) {
    return instance.request({
        url: `/assets/sms/${smsId}/review/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得 sms 審核紀錄
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getSmsReviewers(uuid, config = {}) {
    return instance.request({
        url: `/assets/sms/${uuid}/reviewers/`,
        method: "get",
        ...config,
    })
}

/**
 * 預覽 sms
 * @param {object} config config
 * @returns {Promise} API response
 */
export function sendSmsPreview(config = {}) {
    return instance.request({
        url: "/assets/sms/send-preview/",
        method: "post",
        ...config,
    })
}

/**
 * 取得 sms 短網址服務商
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getShortenUrlServices(config = {}) {
    return instance.request({
        url: "/assets/sms/shorten-url/services/",
        method: "get",
        ...config,
    })
}

//============================ APP推播 ============================//

/**
 * 取得 app push 素材各狀態數量
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getAppPushStatusCount(config = {}) {
    return instance.request({
        url: "/assets/push/counts/",
        method: "get",
        ...config,
    })
}

/**
 * 取得 APP推播
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getAppPushes(config = {}) {
    return instance.request({
        url: "/assets/push/",
        method: "get",
        ...config,
    })
}

/**
 * 取得單個 APP推播
 * @param {string} appPushId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getAppPush(appPushId, config = {}) {
    return instance.request({
        url: `/assets/push/${appPushId}/`,
        method: "get",
        ...config,
    })
}

/**
 * 新增 APP推播
 * @param {object} config config
 * @returns {Promise} API response
 */
export function createAppPush(config = {}) {
    return instance.request({ url: "/assets/push/", method: "post", ...config })
}

/**
 * 編輯 APP推播
 * @param {string} appPushId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateAppPush(appPushId, config = {}) {
    return instance.request({
        url: `/assets/push/${appPushId}/`,
        method: "put",
        ...config,
    })
}

/**
 * 複製 APP推播
 * @param {string} appPushId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function copyAppPush(appPushId, config = {}) {
    return instance.request({
        url: `/assets/push/${appPushId}/copy/`,
        method: "post",
        ...config,
    })
}

/**
 * 停用 APP推播
 * @param {string} appPushId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function disableAppPush(appPushId, config = {}) {
    return instance.request({
        url: `/assets/push/${appPushId}/disable/`,
        method: "put",
        ...config,
    })
}

/**
 * 刪除 APP推播
 * @param {string} appPushId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function deleteAppPush(appPushId, config = {}) {
    return instance.request({
        url: `/assets/push/${appPushId}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 送審 APP推播
 * @param {string} appPushId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function submitAppPush(appPushId, config = {}) {
    return instance.request({
        url: `/assets/push/${appPushId}/submit/`,
        method: "post",
        ...config,
    })
}

/**
 * 審核 APP推播
 * @param {string} appPushId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function reviewAppPush(appPushId, config = {}) {
    return instance.request({
        url: `/assets/push/${appPushId}/review/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得 APP推播 審核紀錄
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getAppPushReviewers(uuid, config = {}) {
    return instance.request({
        url: `/assets/push/${uuid}/reviewers/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得 APP推播 推播人員列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getAppPushReceivers(config = {}) {
    return instance.request({
        url: `/assets/push/receivers/`,
        method: "get",
        ...config,
    })
}

/**
 * APP推播 測試推播
 * @param {object} config config
 * @returns {Promise} API response
 */
export function testAppPush(config = {}) {
    return instance.request({
        url: `/assets/push/test/`,
        method: "post",
        ...config,
    })
}

//============================ EDM ============================//

/**
 * 取得 edm 偏好設定
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getEdmPreferences(config = {}) {
    return instance.request({
        url: "/assets/edm/preferences/",
        method: "get",
        ...config,
    })
}

/**
 * 更新 edm 偏好設定
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateEdmPreferences(config = {}) {
    return instance.request({
        url: "/assets/edm/preferences/",
        method: "put",
        ...config,
    })
}

/**
 * 取得 edm 素材各狀態數量
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getEdmStatusCount(config = {}) {
    return instance.request({
        url: "/assets/edm/counts/",
        method: "get",
        ...config,
    })
}

/**
 * 取得 edm 素材列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getEdmList(config = {}) {
    return instance.request({
        url: "/assets/edm/",
        method: "get",
        ...config,
    })
}

/**
 * 取得單個 edm 素材
 * @param {string} edmAssetId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getEdm(edmAssetId, config = {}) {
    return instance.request({
        url: `/assets/edm/${edmAssetId}/`,
        method: "get",
        ...config,
    })
}

/**
 * 新增 edm 素材
 * @param {object} config config
 * @returns {Promise} API response
 */
export function createEdm(config = {}) {
    return instance.request({
        url: "/assets/edm/",
        method: "post",
        ...config,
    })
}

/**
 * 編輯 edm 素材
 * @param {string} edmAssetId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateEdm(edmAssetId, config = {}) {
    return instance.request({
        url: `/assets/edm/${edmAssetId}/`,
        method: "put",
        ...config,
    })
}

/**
 * 複製 edm 素材
 * @param {string} edmAssetId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function copyEdm(edmAssetId, config = {}) {
    return instance.request({
        url: `/assets/edm/${edmAssetId}/copy/`,
        method: "post",
        ...config,
    })
}

/**
 * 停用 edm 素材
 * @param {string} edmAssetId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function disableEdm(edmAssetId, config = {}) {
    return instance.request({
        url: `/assets/edm/${edmAssetId}/disable/`,
        method: "put",
        ...config,
    })
}

/**
 * 刪除 edm 素材
 * @param {string} edmAssetId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function deleteEdm(edmAssetId, config = {}) {
    return instance.request({
        url: `/assets/edm/${edmAssetId}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 送審 edm 素材
 * @param {string} edmAssetId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function submitEdm(edmAssetId, config = {}) {
    return instance.request({
        url: `/assets/edm/${edmAssetId}/submit/`,
        method: "post",
        ...config,
    })
}

/**
 * 審核 edm 素材
 * @param {string} edmAssetId id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function reviewEdm(edmAssetId, config = {}) {
    return instance.request({
        url: `/assets/edm/${edmAssetId}/review/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得 edm 素材 審核紀錄
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getEdmReviewers(uuid, config = {}) {
    return instance.request({
        url: `/assets/edm/${uuid}/reviewers/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得 預覽收件人列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getEdmReceivers(config = {}) {
    return instance.request({
        url: "/assets/edm/receivers/",
        method: "get",
        ...config,
    })
}

/**
 * 新增 預覽收件人
 * @param {object} config config
 * @returns {Promise} API response
 */
export function addEdmReceiver(config = {}) {
    return instance.request({
        url: "/assets/edm/receivers/",
        method: "post",
        ...config,
    })
}

/**
 * 刪除 預覽收件人
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function deleteEdmReceiver(uuid, config = {}) {
    return instance.request({
        url: "/assets/edm/receivers/" + uuid + "/",
        method: "delete",
        ...config,
    })
}

/**
 * 預覽 EDM
 * @param {object} config config
 * @returns {Promise} API response
 */
export function sendEdmPreview(config = {}) {
    return instance.request({
        url: "/assets/edm/send-preview/",
        method: "post",
        ...config,
    })
}

//============================ 素材管理 ============================//

/**
 * 取得素材狀態
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getAssetStatus(config = {}) {
    return instance.request({
        url: "/assets/status/",
        method: "get",
        ...config,
    })
}

/**
 * 取得個人化變數
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getMemberMessageParams(config = {}) {
    return instance.request({
        url: "/assets/member-message-params/",
        method: "get",
        ...config,
    })
}
