import { instance } from "../axios"

/**
 * 取得消息通知列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getNotificationsList(config = {}) {
    return instance.request({
        url: "/notifications/",
        method: "get",
        ...config,
    })
}

/**
 * 發送公告
 * @param {object} config config
 * @returns {Promise} API response
 */
export function createNotification(config = {}) {
    return instance.request({
        url: "/notifications/",
        method: "post",
        ...config,
    })
}

/**
 * 更改消息已讀未讀狀態
 * @param {object} config config
 * @returns {Promise} API response
 */
export function changeNotificationsRead(config = {}) {
    return instance.request({
        url: "/notifications/read/",
        method: "patch",
        ...config,
    })
}

/**
 * 取得消息詳細資訊
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getNotificationInfo(uuid, config = {}) {
    return instance.request({
        url: `/notifications/${uuid}/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得待審核列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getPendingReviewList(config = {}) {
    return instance.request({
        url: "/review/pending-review/",
        method: "get",
        ...config,
    })
}

/**
 * 取得審核紀錄列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getReviewHistoryList(config = {}) {
    return instance.request({
        url: "/review/review-history/",
        method: "get",
        ...config,
    })
}

/**
 * 取得送審紀錄列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getSubmitHistoryList(config = {}) {
    return instance.request({
        url: "/review/submit-history/",
        method: "get",
        ...config,
    })
}

/**
 * 取得審核類型選項
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getReviewType(config = {}) {
    return instance.request({
        url: "/review/type/",
        method: "get",
        ...config,
    })
}

/**
 * 取得審核動作選項
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getReviewAction(config = {}) {
    return instance.request({
        url: "/review/action/",
        method: "get",
        ...config,
    })
}

/**
 * 取得審核狀態選項
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getReviewStatus(config = {}) {
    return instance.request({
        url: "/review/status/",
        method: "get",
        ...config,
    })
}

/**
 * 取得消息通知類型
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getNotificationType(config = {}) {
    return instance.request({
        url: "/notifications/type/",
        method: "get",
        ...config,
    })
}

/**
 * 取得消息通知來源
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getNotificationSource(config = {}) {
    return instance.request({
        url: "/notifications/source/",
        method: "get",
        ...config,
    })
}
