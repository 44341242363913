import { instance } from "../axios"

/**
 * 取得用戶資訊
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getAccountInfo(config = {}) {
    return instance.request({
        url: "/accounts/current/",
        method: "get",
        ...config,
    })
}

/**
 * 取得用戶權限
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getAccountPermission(config = {}) {
    return instance.request({
        url: `/accounts/current/permissions/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得用戶消息顯示設定
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getAccountNotificationSettings(config = {}) {
    return instance.request({
        url: `/accounts/current/notification-settings/`,
        method: "get",
        ...config,
    })
}

/**
 * 更新用戶消息顯示設定
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function updateAccountNotificationSettings(config = {}) {
    return instance.request({
        url: `/accounts/current/notification-settings/`,
        method: "put",
        ...config,
    })
}

/**
 * 取得用戶通知計數
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getAccountNotificationCounter(config = {}) {
    return instance.request({
        url: `/accounts/current/notification-counter/`,
        method: "get",
        ...config,
    })
}
