import useRouterProtect from "@/composables/useRouterProtect/index"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import * as audiencesApi from "@/api/audience/api"
import * as tagsApi from "@/api/tags/api"
import PERMISSIONS from "@/utils/permissions/index.js"

export default [
    {
        path: "/audiences",
        component: DefaultLayout,
        name: "audiences",
        redirect: () => {
            return { name: "audiences-management" }
        },
        meta: {
            isShow: true,
            name: "受眾篩選",
            index: "2",
            position: "left",
            menuGroup: "audiences",
        },
        children: [
            {
                path: "",
                name: "audiences-management",
                redirect: () => {
                    return { name: "audiences-list" }
                },
                meta: {
                    isShow: true,
                    requiresAuth: true,
                    permissions: [
                        PERMISSIONS.VIEW_AUDIENCE,
                        PERMISSIONS.VIEW_ARCHIVED_AUDIENCE,
                    ],
                    isSubShow: true,
                    subIndex: "1",
                    subGroup: "audiences",
                    hasSubMenuGroup: true,
                    subMenuGroup: "受眾分群管理",
                    subMenuGroupIndex: "1",
                },
                children: [
                    // 受眾管理列表
                    {
                        path: "",
                        component: () =>
                            import("@/pages/AudiencesGroup/AudiencesPage.vue"),
                        name: "audiences-list",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_AUDIENCE],
                            isSubShow: true,
                            subIndex: "1-1",
                            subGroup: "audiences",
                            subGroupName: "受眾管理列表",
                            hasSubMenuGroup: false,
                            alternativeGroup: "audience-management",
                        },
                        props: { isArchived: false },
                    },
                    // 受眾封存區
                    {
                        path: "archived",
                        component: () =>
                            import("@/pages/AudiencesGroup/AudiencesPage.vue"),
                        name: "audiences-archived",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_ARCHIVED_AUDIENCE],
                            isSubShow: true,
                            subIndex: "1-2",
                            subGroup: "audiences",
                            subGroupName: "受眾封存區",
                            hasSubMenuGroup: false,
                            alternativeGroup: "audience-management",
                        },
                        props: { isArchived: true },
                    },
                ],
            },
            {
                path: ":id", // 受眾詳情
                name: "audiences-detail",
                component: () =>
                    import("@/pages/AudiencesGroup/AudiencesDetailPage.vue"),
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.VIEW_AUDIENCE_DETAIL],
                    isSubShow: false,
                    subGroup: "audiences",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.VIEW_AUDIENCE_DETAIL,
                        api: audiencesApi.getAudienceInfo,
                    })

                    if (path === true) {
                        to.params.initialAudiencesResponse = response
                    }
                    return path
                },
                children: [
                    {
                        path: "detail-conditions",
                        name: "audiences-detail-conditions",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesDetailConditionsPage.vue"
                            ),
                        props: true,
                    },
                    {
                        path: "detail-members",
                        name: "audiences-detail-members",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesDetailMembersPage.vue"
                            ),
                        props: true,
                    },
                    {
                        path: "detail-analysis",
                        name: "audiences-detail-analysis",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesDetailAnalysisPage.vue"
                            ),
                        props: true,
                    },
                ],
            },
            {
                path: "add", // 新增受眾
                name: "add-audiences",
                component: () =>
                    import("@/pages/AudiencesGroup/AudiencesAddPage.vue"),
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.ADD_AUDIENCE],
                    isSubShow: false,
                    subGroup: "audiences",
                }),
            },
            {
                path: ":id/edit", // 編輯受眾
                name: "edit-audiences",
                component: () =>
                    import("@/pages/AudiencesGroup/AudiencesEditPage.vue"),
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.EDIT_AUDIENCE],
                    isSubShow: false,
                    subGroup: "audiences",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.EDIT_AUDIENCE,
                        api: audiencesApi.getAudienceInfo,
                    })
                    if (path === true) {
                        to.params.initialAudiencesResponse = response
                    }
                    return path
                },
            },
            {
                path: "comparison", // 比較受眾差異，例：/comparison?uuid=oneId&item2=twoId
                name: "comparison-audiences",
                component: () =>
                    import(
                        "@/pages/AudiencesGroup/AudiencesComparisonPage.vue"
                    ),
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.VIEW_AUDIENCE],
                    isSubShow: false,
                    subGroup: "audiences",
                }),
                beforeEnter(to) {
                    if (!to.query?.uuids?.length) {
                        return { name: "audiences-list" }
                    }
                },
            },

            {
                name: "tag-management",
                path: "",
                redirect: () => {
                    return { name: "audiences-tags" }
                },
                meta: {
                    isShow: true,
                    requiresAuth: true,
                    permissions: [
                        PERMISSIONS.VIEW_TAG,
                        PERMISSIONS.VIEW_ARCHIVED_TAG,
                    ],
                    isSubShow: true,
                    subIndex: "2",
                    subGroup: "tags",
                    hasSubMenuGroup: true,
                    subMenuGroup: "標籤管理",
                    subMenuGroupIndex: "2",
                },
                children: [
                    // 標籤管理列表
                    {
                        path: "tags",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTagsPage.vue"
                            ),
                        name: "audiences-tags",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_TAG],
                            isSubShow: true,
                            subIndex: "2-1",
                            subGroup: "tags",
                            subGroupName: "標籤管理列表",
                            hasSubMenuGroup: false,
                            alternativeGroup: "audience-management",
                        },
                        props: { isArchived: false },
                    },
                    {
                        path: "archived",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTagsPage.vue"
                            ),
                        name: "audiences-tags-archived",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_ARCHIVED_TAG],
                            isSubShow: true,
                            subIndex: "2-2",
                            subGroup: "tags",
                            subGroupName: "標籤封存區",
                            hasSubMenuGroup: false,
                            alternativeGroup: "audience-management",
                        },
                        props: { isArchived: true },
                    },
                ],
            },
            {
                path: "tags/:id", // 標籤詳情
                name: "audiences-tags-detail",
                component: () =>
                    import(
                        "@/pages/AudiencesGroup/AudiencesTagsDetailPage.vue"
                    ),
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.VIEW_TAG_DETAIL],
                    isSubShow: false,
                    subGroup: "tags",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.VIEW_TAG_DETAIL,
                        api: tagsApi.getTagInfo,
                    })

                    if (path === true) {
                        to.params.initialTagsResponse = response
                    }
                    return path
                },
                children: [
                    {
                        path: "",
                        name: "audiences-tags-detail-members", // 標籤會員名單
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTagsDetailMembersPage.vue"
                            ),
                        props: true,
                    },
                    {
                        path: "",
                        name: "audiences-tags-detail-analysis", // 標籤分析名單
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTagsDetailAnalysisPage.vue"
                            ),
                        props: true,
                    },
                ],
            },
            {
                path: "tags/:id/edit", // 編輯標籤
                name: "audiences-edit-tag",
                component: () =>
                    import("@/pages/AudiencesGroup/AudiencesTagsEditPage.vue"),
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.EDIT_TAG],
                    isSubShow: false,
                    subGroup: "tags",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.EDIT_TAG,
                        api: tagsApi.getTagInfo,
                    })

                    if (path === true) {
                        to.params.initialTagsResponse = response
                    }
                    return path
                },
            },
            {
                path: "tags/comparison", // 標籤比較
                name: "audiences-tags-comparison",
                component: () =>
                    import(
                        "@/pages/AudiencesGroup/AudiencesTagsComparisonPage.vue"
                    ),
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.VIEW_TAG],
                    isSubShow: false,
                    subGroup: "tags",
                }),
            },
            {
                name: "tag-attacher-management",
                path: "tag-attacher-management",
                redirect: () => {
                    return { name: "audiences-tag-attachers" }
                },
                meta: {
                    isShow: true,
                    requiresAuth: true,
                    permissions: [
                        PERMISSIONS.VIEW_TAG_ASSIGNER,
                        PERMISSIONS.VIEW_ARCHIVED_TAG_ASSIGNER,
                    ],
                    isSubShow: true,
                    subIndex: "3",
                    subGroup: "tag-attachers",
                    hasSubMenuGroup: true,
                    subMenuGroup: "篩選貼標管理",
                    subMenuGroupIndex: "3",
                },
                children: [
                    // 篩選貼標管理列表
                    {
                        path: "tag-attachers", // 貼標列表
                        name: "audiences-tag-attachers",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTagAttachersPage.vue"
                            ),
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_TAG_ASSIGNER],
                            isSubShow: true,
                            subGroup: "tag-attachers",
                            subIndex: "3-1",
                            subGroupName: "篩選貼標列表",
                            alternativeGroup: "audience-management",
                        },
                        props: { isArchived: false },
                    },
                    {
                        path: "archived",
                        name: "audiences-tag-attachers-archived",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTagAttachersPage.vue"
                            ),
                        meta: {
                            requiresAuth: true,
                            permissions: [
                                PERMISSIONS.VIEW_ARCHIVED_TAG_ASSIGNER,
                            ],
                            isSubShow: true,
                            subIndex: "3-2",
                            subGroup: "tag-attachers",
                            subGroupName: "篩選貼標封存區",
                            hasSubMenuGroup: false,
                            alternativeGroup: "audience-management",
                        },
                        props: { isArchived: true },
                    },
                    {
                        path: "add-tagging", // 新增貼標
                        name: "audiences-add-tagging",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesAddOrEditTaggingPage.vue"
                            ),
                        meta: createAuthRouteMeta({
                            requiresAuth: true,
                            subIndex: "3-3",
                            subGroupName: "新增貼標",
                            permissions: [PERMISSIONS.ADD_TAG_ASSIGNER],
                            isSubShow: false,
                            subGroup: "tag-attachers",
                        }),
                    },
                    {
                        path: "tagging/:id/edit", // 編輯貼標
                        name: "audiences-edit-tagging",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesAddOrEditTaggingPage.vue"
                            ),
                        meta: createAuthRouteMeta({
                            requiresAuth: true,
                            subIndex: "3-4",
                            subGroupName: "編輯貼標",
                            permissions: [PERMISSIONS.EDIT_TAG_ASSIGNER],
                            isSubShow: false,
                            subGroup: "tag-attachers",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.EDIT_TAG_ASSIGNER,
                                api: tagsApi.getTagAssigner,
                            })

                            if (path === true) {
                                to.params.initialTagAssignerResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "tagging/:id", // 篩選貼標詳細頁
                        name: "audiences-tagging-detail",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTaggingDetailPage.vue"
                            ),
                        meta: createAuthRouteMeta({
                            requiresAuth: true,
                            subIndex: "3-5",
                            subGroupName: "檢視貼標",
                            permissions: [PERMISSIONS.VIEW_TAG_ASSIGNER_DETAIL],
                            isSubShow: false,
                            subGroup: "tag-attachers",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS:
                                    PERMISSIONS.VIEW_TAG_ASSIGNER_DETAIL,
                                api: tagsApi.getTagAssigner,
                            })

                            if (path === true) {
                                to.params.initialTagAssignerResponse = response
                            }
                            return path
                        },
                        children: [
                            {
                                path: "detail-conditions",
                                name: "audiences-tagging-detail-conditions",
                                component: () =>
                                    import(
                                        "@/pages/AudiencesGroup/AudiencesTaggingDetailConditionsPage.vue"
                                    ),
                                meta: createAuthRouteMeta({
                                    requiresAuth: true,
                                    subIndex: "3-5-1",
                                    subGroupName: "檢視貼標",
                                    permissions: [
                                        PERMISSIONS.VIEW_TAG_ASSIGNER_DETAIL,
                                    ],
                                    isSubShow: false,
                                    subGroup: "tag-attachers",
                                }),
                                props: true,
                            },
                            {
                                path: "detail-members",
                                name: "audiences-tagging-detail-members",
                                component: () =>
                                    import(
                                        "@/pages/AudiencesGroup/AudiencesTaggingDetailMembersPage.vue"
                                    ),
                                meta: createAuthRouteMeta({
                                    requiresAuth: true,
                                    subIndex: "3-5-2",
                                    subGroupName: "檢視貼標",
                                    permissions: [
                                        PERMISSIONS.VIEW_TAG_ASSIGNER_DETAIL,
                                    ],
                                    isSubShow: false,
                                    subGroup: "tag-attachers",
                                }),
                                props: true,
                            },
                            {
                                path: "detail-analysis",
                                name: "audiences-tagging-detail-analysis",
                                component: () =>
                                    import(
                                        "@/pages/AudiencesGroup/AudiencesTaggingDetailAnalysisPage.vue"
                                    ),
                                meta: createAuthRouteMeta({
                                    requiresAuth: true,
                                    subIndex: "3-5-3",
                                    subGroupName: "檢視貼標",
                                    permissions: [
                                        PERMISSIONS.VIEW_TAG_ASSIGNER_DETAIL,
                                    ],
                                    isSubShow: false,
                                    subGroup: "tag-attachers",
                                }),
                                props: true,
                            },
                        ],
                    },
                    {
                        path: "tagging/comparison", // 比較貼標
                        name: "audiences-tagging-comparison",
                        component: () =>
                            import(
                                "@/pages/AudiencesGroup/AudiencesTaggingComparisonPage.vue"
                            ),
                        meta: createAuthRouteMeta({
                            requiresAuth: true,
                            subIndex: "3-6",
                            subGroupName: "比較貼標",
                            permissions: [PERMISSIONS.VIEW_TAG_ASSIGNER],
                            isSubShow: false,
                            subGroup: "tag-attachers",
                        }),
                    },
                ],
            },
            // 受眾圖表詳細頁
            {
                path: ":previousRoute/dashboards/:dashboardSlug/charts/:chartSlug/detail", // 圖表詳情
                name: "audience-dashboard-chart-detail",
                component: () =>
                    import(
                        "@/pages/MemberManage/MemberDashboardChartDetail.vue"
                    ),
                meta: {
                    requiresAuth: false,
                    isSubShow: false,
                    subGroup: "audience",
                },
            },
            // {
            //     path: "filter-config", // 篩選條件設定表
            //     name: "filter-configs",
            //     component: () =>
            //         import(
            //             "@/pages/AudiencesGroup/AudiencesFilterConfigurationPage.vue"
            //         ),
            //     meta: {
            //         requiresAuth: true,
            //         permissions: [PERMISSIONS.VIEW_AUDIENCE], // 具備受眾條件後，可檢視此頁
            //         isSubShow: true,
            //         subIndex: "4",
            //         subGroup: "filter",
            //         subGroupName: "篩選條件設定表",
            //     },
            // },
        ],
    },
]
