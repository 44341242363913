import { createApp, markRaw } from "vue"
import { createPinia } from "pinia"
import * as Sentry from "@sentry/vue"
import { createSentryPiniaPlugin } from "@sentry/vue"

import App from "./App.vue"
import router from "@/router"
import i18n from "@/plugins/i18n"
import "@/assets/styles/reset.scss"
import "@/assets/styles/custom-element-plus.scss"
import "virtual:uno.css"
import VueDOMPurifyHTML from "vue-dompurify-html"
// this global registration is for cron-element-plus
import {
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    ElButton,
    ElIcon,
} from "element-plus"

// prepareMSW().then(() => {
startApp()
// })

/**
 * 啟用 msw server（只在開發環境中）
 * @see https://mswjs.io/docs/recipes/deferred-mounting
 */
// async function prepareMSW() {
//     if (import.meta.env.DEV) {
//         const { worker } = await import("./mocks/browser.js")
//         worker.start({
//             onUnhandledRequest: "bypass",
//         })
//     }
// }

/**
 * 創建 Vue app instance
 */
function startApp() {
    const pinia = createPinia().use(({ store }) => {
        store.$router = markRaw(router)
    })
    pinia.use(createSentryPiniaPlugin())

    const app = createApp(App)

    const baseURL = import.meta.env.DEV ? "" : import.meta.env.VITE_BASE_URL

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        environment: import.meta.env.VITE_ENVIRONMENT,
        trackComponents: true,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.3,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ["localhost", baseURL],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })

    app.component("el-dropdown", ElDropdown)
    app.component("el-dropdown-item", ElDropdownItem)
    app.component("el-dropdown-menu", ElDropdownMenu)
    app.component("el-button", ElButton)
    app.component("el-icon", ElIcon)
    app.use(i18n)
    app.use(router)
    app.use(pinia)
    app.use(VueDOMPurifyHTML)
    app.mount("#app")
}
