<script setup>
    import { defineAsyncComponent } from "vue"

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: "#333",
        },
        fill: {
            type: String,
            default: "none",
        },
        width: {
            type: String,
            default: "20",
        },
        height: {
            type: String,
            default: "20",
        },
    })

    const AsyncComp = defineAsyncComponent(() =>
        import(`../../../assets/images/icons/${props.name}.svg`)
    )
</script>

<template>
    <AsyncComp
        :width="width"
        :height="height"
        :color="color"
        :fill="fill"
        :style="{
            width: `${width}px`,
            height: `${height}px`,
            display: 'inline-flex',
        }"
        class="svg-icon"
    />
</template>

<style lang="scss" scoped></style>
