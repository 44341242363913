import useRouterProtect from "@/composables/useRouterProtect"
import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import PERMISSIONS from "@/utils/permissions/index.js"
import * as notificationApi from "@/api/notification/api.js"

export default [
    {
        path: "/notification-center",
        component: DefaultLayout,
        name: "notification-center",
        redirect: () => {
            return { name: "notification-list" }
        },
        meta: {
            isShow: false,
            name: "消息中心",
            menuGroup: "notification-center",
        },
        children: [
            // 消息通知
            {
                path: "",
                component: () =>
                    import(
                        "@/pages/NotificationGroup/NotificationListPage.vue"
                    ),
                name: "notification-list",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_NOTIFICATION],
                    isSubShow: true,
                    subGroup: "notification-list",
                    subIndex: "1-1",
                    subGroupName: "消息通知",
                },
            },
            // 發送公告
            {
                path: "add",
                component: () =>
                    import("@/pages/NotificationGroup/NotificationAddPage.vue"),
                name: "add-notification",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.ADD_NOTIFICATION],
                    isSubShow: false,
                    subGroup: "add-notification",
                },
            },
            // 檢視消息詳細頁
            {
                path: ":id",
                component: () =>
                    import(
                        "@/pages/NotificationGroup/NotificationDetailPage.vue"
                    ),
                name: "notification-detail",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_NOTIFICATION_DETAIL],
                    isSubShow: false,
                    subGroup: "notification-detail",
                },
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.VIEW_NOTIFICATION_DETAIL,
                        api: notificationApi.getNotificationInfo,
                    })

                    if (path === true) {
                        to.params.initialNotificationResponse = response
                    }
                    return path
                },
            },
            // 編審中心
            {
                path: "",
                name: "review-center",
                redirect: () => {
                    return { name: "pending-review" }
                },
                meta: {
                    requiresAuth: true,
                    permissions: [
                        PERMISSIONS.VIEW_REVIEW_LIST,
                        PERMISSIONS.VIEW_REVIEW_RECORD,
                        PERMISSIONS.VIEW_SUBMIT_LIST,
                    ],
                    isSubShow: true,
                    subGroup: "review-center",
                    subIndex: "1-2",
                    hasSubMenuGroup: true,
                    subMenuGroup: "編審中心",
                    subMenuGroupIndex: "1-2",
                },
                children: [
                    // 待審核
                    {
                        path: "pending-review",
                        component: () =>
                            import(
                                "@/pages/NotificationGroup/PendingReviewPage.vue"
                            ),
                        name: "pending-review",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_REVIEW_LIST],
                            isSubShow: true,
                            subGroup: "review-center",
                            subIndex: "1-2-1",
                            subGroupName: "待審核",
                            alternativeGroup: "review-center",
                        },
                    },
                    // 審核紀錄
                    {
                        path: "review-history",
                        component: () =>
                            import(
                                "@/pages/NotificationGroup/ReviewHistoryPage.vue"
                            ),
                        name: "review-history",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_REVIEW_RECORD],
                            isSubShow: true,
                            subGroup: "review-center",
                            subIndex: "1-2-2",
                            subGroupName: "審核紀錄",
                            alternativeGroup: "review-center",
                        },
                    },
                    // 送審紀錄
                    {
                        path: "submission-history",
                        component: () =>
                            import(
                                "@/pages/NotificationGroup/SubmissionHistoryPage.vue"
                            ),
                        name: "submission-history",
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_SUBMIT_LIST],
                            isSubShow: true,
                            subGroup: "review-center",
                            subIndex: "1-2-3",
                            subGroupName: "送審紀錄",
                            alternativeGroup: "review-center",
                        },
                    },
                ],
            },
            // 下載中心
            {
                path: "download",
                component: () => import("@/pages/CommonGroup/DownloadPage.vue"),
                name: "download-list",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_DOWNLOAD],
                    isSubShow: true,
                    subGroup: "download-list",
                    subIndex: "1-3",
                    subGroupName: "下載中心",
                },
            },
        ],
    },
]
