import * as sendingConfigurationApi from "@/api/sendingConfiguration/api.js"
import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import PERMISSIONS from "@/utils/permissions/index.js"
// import * as channelApi from "@/api/channel/api.js"

export default [
    {
        path: "/platform-settings",
        component: DefaultLayout,
        name: "platform-settings",
        redirect: () => {
            return { name: "channel-management" }
        },
        meta: {
            isShow: true,
            name: "平台設定",
            menuGroup: "platform-settings",
        },
        children: [
            // 平台管理
            {
                path: "",
                name: "platform-management",
                redirect: () => {
                    return { name: "sending-configuration" }
                },
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_PLATFORM_MANAGEMENT],
                    isSubShow: true,
                    subIndex: "2",
                    subGroup: "platform-management",
                    hasSubMenuGroup: true,
                    subMenuGroup: "平台管理",
                    subMenuGroupIndex: "2",
                },
                children: [
                    {
                        path: "sending-configuration",
                        name: "sending-configuration",
                        component: () =>
                            import(
                                "@/pages/PlatformSettingsGroup/SendingConfiguration.vue"
                            ),
                        meta: createAuthRouteMeta({
                            permissions: [PERMISSIONS.VIEW_PLATFORM_MANAGEMENT],
                            isSubShow: true,
                            subIndex: "2-1",
                            subGroupName: "預覽與寄件人管理",
                            alternativeGroup: "platform-management",
                        }),
                        props: true,
                        beforeEnter: async (to) => {
                            try {
                                const response =
                                    await sendingConfigurationApi.getSendingConfiguration()
                                to.params.initialConfigurationResponse =
                                    response
                                return true
                            } catch (error) {
                                return false
                            }
                        },
                    },
                ],
            },
        ],
    },
]
