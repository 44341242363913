import { instance } from "../axios"

/**
 * 取得會員列表
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getMemberList(config = {}) {
    return instance.request({
        url: "/members/",
        method: "get",
        ...config,
    })
}

/**
 * 取得會員詳細資料
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getMemberDetail(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/`,
        method: "get",
        ...config,
    })
}

/**
 * 更新會員詳細資料
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateMemberDetail(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/`,
        method: "put",
        ...config,
    })
}

/**
 * 更新會員詳細資料
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function patchMemberDetail(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/`,
        method: "patch",
        ...config,
    })
}

/**
 * 更新會員封鎖狀態
 * @param {string} memberUuid id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function patchMemberBlocking(memberUuid, config = {}) {
    return instance.request({
        url: `/members/${memberUuid}/block-info/`,
        method: "patch",
        ...config,
    })
}

/**
 * 更新會員封鎖狀態
 * @param {string} memberUuid id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateMemberBlocking(memberUuid, config = {}) {
    return instance.request({
        url: `/members/${memberUuid}/block-info/`,
        method: "put",
        ...config,
    })
}

/**
 * 取得會員活動列表
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getMemberEvents(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/events/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得會員旅程活動列表
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getMemberJourneyEvents(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/journey-events/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得會員標籤列表
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getMemberTags(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/tags/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得會員備註列表
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getMemberNotes(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/notes/`,
        method: "get",
        ...config,
    })
}

/**
 * 建立會員備註列表
 * @param {string} memberId member id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function addMemberNotes(memberId, config = {}) {
    return instance.request({
        url: `/members/${memberId}/notes/`,
        method: "post",
        ...config,
    })
}
