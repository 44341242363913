import { instance } from "../axios"

/**
 * 取得系統設定
 * @param {object} [config] axios config
 * @returns {Promise} API response
 */
export function getConfiguration(config = {}) {
    return instance.request({
        url: "/systems/configuration/",
        method: "get",
        ...config,
    })
}

/**
 * 設置系統設定
 * @param {object} config config
 * @returns {Promise} Api response
 */
export function updateConfiguration(config = {}) {
    return instance.request({
        url: `/systems/configuration/`,
        method: "put",
        ...config,
    })
}
