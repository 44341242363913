import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import PERMISSIONS from "@/utils/permissions/index.js"
import useRouterProtect from "@/composables/useRouterProtect/index.js"
import * as usersAPI from "@/api/users/api"
import { formatUserDataToRouterProtectStyle } from "@/api/users/utils"

export default [
    {
        path: "/account-manage",
        component: DefaultLayout,
        name: "account-manage",
        redirect: () => {
            return { name: "permissions-setting" }
        },
        meta: {
            isShow: false,
            name: "權限管理",
            menuGroup: "account-manage",
        },
        children: [
            // 角色管理
            {
                path: "permissions-setting",
                component: () =>
                    import(
                        "@/pages/AccountManageGroup/AccountManagePermissionSettingPage.vue"
                    ),
                name: "permissions-setting",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_ROLE],
                    isSubShow: true,
                    subIndex: "1",
                    subGroup: "permissions-setting",
                    subGroupName: "角色管理",
                },
            },
            // 使用者管理列表
            {
                path: "accounts",
                component: () =>
                    import(
                        "@/pages/AccountManageGroup/AccountManageAccountsPage.vue"
                    ),
                name: "accounts-list",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_USER],
                    isSubShow: true,
                    subIndex: "2",
                    subGroup: "accounts",
                    subGroupName: "使用者管理",
                },
            },
            // 編輯使用者
            {
                path: "accounts/:id/edit",
                component: () =>
                    import(
                        "@/pages/AccountManageGroup/AccountManageAccountsEditPage.vue"
                    ),
                name: "edit-accounts",
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.EDIT_USER],
                    isSubShow: false,
                    subGroup: "accounts",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.EDIT_USER,
                        api: usersAPI.getUser,
                        formatFunction: formatUserDataToRouterProtectStyle,
                    })

                    if (path === true) {
                        to.params.initialUserDataResponse = response
                    }
                    return path
                },
            },
            // 操作歷程
            {
                path: "operation-history",
                component: () =>
                    import(
                        "@/pages/AccountManageGroup/OperationHistoryPage.vue"
                    ),
                name: "operation-history",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_OPERATION_HISTORY],
                    isSubShow: true,
                    subGroup: "operation-history",
                    subIndex: "3",
                    subGroupName: "操作歷程",
                },
            },
        ],
    },
]
