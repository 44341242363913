/**
 * 權限及狀態的名稱、資訊集中管理檔案
 */

const PERMISSIONS = {
    // ==數據管理 datahub==
    // 數據報表
    VIEW_DASHBOARD: "view_dashboard",
    EXPORT_DASHBOARD: "export_dashboard",
    // 上傳中心
    VIEW_UPLOAD: "view_upload",
    ADD_UPLOAD: "add_upload",
    DELETE_UPLOAD: "delete_upload",
    // 數據品質管理
    VIEW_DATA_BATCH: "view_data_batch",
    // 會員管理
    VIEW_MEMBER_DATA: "view_member_data",
    VIEW_MEMBER_DATA_DETAIL: "view_member_data_detail",
    NOTE_MEMBER_DATA: "note_member_data",
    EDIT_MEMBER_DATA: "edit_member_data",
    EXPORT_MEMBER_DATA: "export_member_data",
    EXPORT_MEMBER_DATA_MEMBER_LIST: "export_member_data_member_list",

    // ==受眾篩選 audience==
    // 受眾管理
    VIEW_AUDIENCE: "view_audience",
    VIEW_AUDIENCE_DETAIL: "view_audience_detail",
    VIEW_ARCHIVED_AUDIENCE: "view_archived_audience",
    ADD_AUDIENCE: "add_audience",
    COPY_AUDIENCE: "copy_audience",
    EDIT_AUDIENCE: "edit_audience",
    SPILT_AUDIENCE: "split_audience",
    DISABLE_AUDIENCE: "disable_audience",
    DELETE_AUDIENCE: "delete_audience",
    VIEW_AUDIENCE_MEMBER_LIST: "view_audience_member_list",
    EXPORT_AUDIENCE_MEMBER_LIST: "export_audience_member_list",
    // 標籤管理
    VIEW_TAG: "view_tag",
    VIEW_TAG_DETAIL: "view_tag_detail",
    VIEW_ARCHIVED_TAG: "view_archived_tag",
    ADD_TAG: "add_tag",
    EDIT_TAG: "edit_tag",
    DISABLE_TAG: "disable_tag",
    DELETE_TAG: "delete_tag",
    VIEW_TAG_MEMBER_LIST: "view_tag_member_list",
    EXPORT_TAG_MEMBER_LIST: "export_tag_member_list",
    // 篩選貼標
    VIEW_TAG_ASSIGNER: "view_tag_assigner",
    VIEW_TAG_ASSIGNER_DETAIL: "view_tag_assigner_detail",
    VIEW_ARCHIVED_TAG_ASSIGNER: "view_archived_tag_assigner",
    ADD_TAG_ASSIGNER: "add_tag_assigner",
    COPY_TAG_ASSIGNER: "copy_tag_assigner",
    EDIT_TAG_ASSIGNER: "edit_tag_assigner",
    DISABLE_TAG_ASSIGNER: "disable_tag_assigner",
    DELETE_TAG_ASSIGNER: "delete_tag_assigner",
    VIEW_TAG_ASSIGNER_MEMBER_LIST: "view_tag_assigner_member_list",
    EXPORT_TAG_ASSIGNER_MEMBER_LIST: "export_tag_assigner_member_list",

    // ==行銷活動 campaign==
    VIEW_CAMPAIGN: "view_campaign",
    VIEW_CAMPAIGN_DETAIL: "view_campaign_detail",
    VIEW_ARCHIVED_CAMPAIGN: "view_archived_campaign",
    ADD_CAMPAIGN: "add_campaign",
    COPY_CAMPAIGN: "copy_campaign",
    EDIT_CAMPAIGN: "edit_campaign",
    SUBMIT_CAMPAIGN: "submit_campaign",
    REVIEW_CAMPAIGN: "review_campaign",
    CANCEL_CAMPAIGN: "cancel_campaign",
    DELETE_CAMPAIGN: "delete_campaign",
    VIEW_CAMPAIGN_MEMBER_LIST: "view_campaign_member_list",
    EXPORT_CAMPAIGN_MEMBER_LIST: "export_campaign_member_list",

    // ==素材管理 asset==
    VIEW_ASSET: "view_asset",
    VIEW_ASSET_DETAIL: "view_asset_detail",
    VIEW_ARCHIVED_ASSET: "view_archived_asset",
    ADD_ASSET: "add_asset",
    COPY_ASSET: "copy_asset",
    EDIT_ASSET: "edit_asset",
    SUBMIT_ASSET: "submit_asset",
    REVIEW_ASSET: "review_asset",
    DISABLE_ASSET: "disable_asset",
    DELETE_ASSET: "delete_asset",

    // ==智能營運 journey==
    VIEW_JOURNEY: "view_journey",
    VIEW_JOURNEY_DETAIL: "view_journey_detail",
    VIEW_ARCHIVED_JOURNEY: "view_archived_journey",
    ADD_JOURNEY: "add_journey",
    COPY_JOURNEY: "copy_journey",
    EDIT_JOURNEY: "edit_journey",
    SUBMIT_JOURNEY: "submit_journey",
    REVIEW_JOURNEY: "review_journey",
    CANCEL_JOURNEY: "cancel_journey",
    DELETE_JOURNEY: "delete_journey",
    VIEW_JOURNEY_MEMBER_LIST: "view_journey_member_list",
    EXPORT_JOURNEY_MEMBER_LIST: "export_journey_member_list",

    // ==權限管理 permission==
    // 角色管理
    VIEW_ROLE: "view_role",
    VIEW_ROLE_DETAIL: "view_role_detail",
    VIEW_ARCHIVED_ROLE: "view_archived_role",
    ADD_ROLE: "add_role",
    EDIT_ROLE: "edit_role",
    INACTIVE_ROLE: "inactive_role",
    DELETE_ROLE: "delete_role",
    // 成員管理
    VIEW_USER: "view_user",
    VIEW_USER_DETAIL: "view_user_detail",
    VIEW_ARCHIVED_USER: "view_archived_user",
    ADD_USER: "add_user",
    EDIT_USER: "edit_user",
    ACTIVATE_USER: "activate_user",
    DISABLE_USER: "disable_user",
    DELETE_USER: "delete_user",
    // 操作歷程
    VIEW_OPERATION_HISTORY: "view_operation_history",

    // ==通知中心 notification==
    // 消息通知
    VIEW_NOTIFICATION: "view_notification",
    VIEW_NOTIFICATION_DETAIL: "view_notification_detail",
    ADD_NOTIFICATION: "add_notification",
    // 編審中心
    VIEW_REVIEW_LIST: "view_review_list",
    VIEW_REVIEW_RECORD: "view_review_record",
    VIEW_SUBMIT_LIST: "view_submit_list",
    // 下載中心
    VIEW_DOWNLOAD: "view_download",
    RETRY_DOWNLOAD: "retry_download",
    ADD_DOWNLOAD: "add_download",

    // ==平台設定 general==
    // 通道管理
    VIEW_CHANNEL_MANAGEMENT: "view_channel_management",
    VIEW_CHANNEL_MANAGEMENT_DETAIL: "view_channel_management_detail",
    EDIT_CHANNEL_MANAGEMENT: "edit_channel_management",
    // 平台管理
    VIEW_PLATFORM_MANAGEMENT: "view_platform_management",
    EDIT_PLATFORM_MANAGEMENT: "edit_platform_management",
}

export default PERMISSIONS

// 角色
export const ROLE = {
    DEPARTMENT_STAFF: "department_staff",
    DEPARTMENT_SUPERVISOR: "department_supervisor",
    SYSTEM_MANAGER: "system_manager",
    ADMINISTRATOR: "administrator",
}

// 角色中文
export const ROLE_CHINESE = {
    [ROLE.DEPARTMENT_STAFF]: "部門成員",
    [ROLE.DEPARTMENT_SUPERVISOR]: "部門主管",
    [ROLE.SYSTEM_MANAGER]: "前台管理員",
    [ROLE.ADMINISTRATOR]: "系統總管理員",
}

// permission scope (EffectiveRange) 自己、部門、更低階、所有、同位階或低階
export const PERMISSION_LIMIT = {
    SAME_PEOPLE: "self",
    SAME_DEPARTMENT: "department",
    LOWER_LEVEL_DEPARTMENT: "lower_level",
    SAME_OR_LOWER_LEVEL: "same_or_lower_level",
    ALL: "all",
}
