import { onMounted, ref, watchEffect } from "vue"
import { ElMessage, ElLoading, ElMessageBox } from "element-plus"
import { useUserStore } from "@/stores/user"

/**
 * 自訂 Hook，用於進行 API 請求。
 * @param {Function} service - API 服務函式。
 * @param {object} options - 可選配置選項。
 * @param {boolean} options.immediate - 是否在元件掛載時立即執行請求。
 * @param {object} options.format - 資料格式化函式，用於轉換回應資料。
 * @param {boolean} options.showError - 是否顯示錯誤日誌。
 * @param {boolean} options.showSuccess - 是否顯示成功日誌。
 * @param {boolean} options.showLoading - 是否顯示等待狀態。
 * @param {string} options.successMessage - 成功訊息。
 * @param {string} options.errorMessage - 錯誤訊息。
 * @param {boolean} options.isThrowError - 當執行 execute 發生錯誤時是否拋出錯誤
 * @param {boolean} options.isErrorUIMessage - 當執行 execute 發生錯誤時是否拋出錯誤
 * @param {boolean} options.initialConfig - 初始 axios config
 * @param {Array} options.args - 傳入 service 的參數
 * @returns {{
 *   data: import('vue').Ref<object>,
 *   isLoading: import('vue').Ref<boolean>,
 *   status: import('vue').Ref<string>,
 *   response: import('vue').Ref<object>,
 *   error: import('vue').Ref<object>,
 *   execute: Function
 * }} - 包含資料、載入狀態、狀態、回應、錯誤以及執行函式的物件。
 */
function useRequest(
    service,
    {
        immediate = true,
        format = null,
        showError = false,
        showSuccess = false,
        showLoading = false,
        errorMessage = "",
        successMessage = "",
        isThrowError = false,
        initialConfig = {},
        isErrorUIMessage = false,
        args = [],
    }
) {
    if (!service) throw Error("缺少 service 函式")

    const data = ref(null)
    const isLoading = ref(false)
    const status = ref("idle") // idle, success, loading, error
    const response = ref(null)
    const error = ref(null)
    const loadingInstance = ref(null)

    watchEffect(() => {
        if (!showLoading) return

        if (isLoading.value) {
            loadingInstance.value = ElLoading.service({
                lock: true,
                text: "Loading",
            })
        } else {
            loadingInstance.value?.close()
        }
    })

    /**
     * 處理錯誤
     * @param {AxiosError} responseError - Axios 錯誤
     */
    function handleError(responseError) {
        status.value = "error"
        error.value = responseError

        if (showError) {
            if (isErrorUIMessage) {
                ElMessage({
                    message: `${error.value.message} (status: ${error.value.status})`,
                    type: "error",
                })
            } else {
                ElMessageBox.alert(
                    errorMessage ||
                        `${error.value.message} (status: ${error.value.status})`,
                    "錯誤",
                    {
                        confirmButtonText: "確認",
                        callback: () => {},
                        type: "error",
                        customClass:
                            "[&>*:first-child>*:first-child]:!justify-start [&_*]:text-left [&>*:last-child]:!justify-end",
                        center: true,
                    }
                )
            }
        }
        if (error.value.status === 401) {
            useUserStore().logout()
        }
        if (isThrowError) throw responseError
    }

    /**
     * 執行 service
     * @param {Array} executeArguments - 傳入 service 的參數
     * @returns {Promise<object>} - response
     */
    async function execute(...executeArguments) {
        const isOptions = (arg) =>
            arg && typeof arg === "object" && "dynamicSuccessMessage" in arg
        const hasOptions = isOptions(
            executeArguments[executeArguments.length - 1]
        )
        const options = hasOptions ? executeArguments.pop() : {}
        const executeArgs = executeArguments

        try {
            error.value = null
            status.value = "loading"
            isLoading.value = true

            const config = {
                ...initialConfig,
                ...(executeArgs[executeArgs.length - 1] || {}),
            }
            response.value = await service(...[...executeArgs, config])

            const formatData = format
                ? format(response.value.data)
                : response.value.data

            data.value = formatData
            response.value.data = formatData

            status.value = "success"

            if (showSuccess) {
                successMessage = options.dynamicSuccessMessage || successMessage
                ElMessage({
                    message: successMessage,
                    type: "success",
                })
            }
        } catch (responseError) {
            handleError(responseError)
        } finally {
            isLoading.value = false
        }

        return response
    }

    onMounted(async () => {
        if (immediate) {
            await execute(...[...args, initialConfig])
        }
    })

    return { data, isLoading, status, response, error, execute }
}

export default useRequest
