const DRAFT_STATUS = "draft"
const ARCHIVED_STATUS = "archived"

export { DRAFT_STATUS, ARCHIVED_STATUS }

const doesSupportReview = import.meta.env.VITE_DOES_SUPPORT_REVIEW === "true"

// 劇本狀態：草稿、審核中、待審核、已通過、生效中、已失效
let ASSETS_STATE_JOURNEY = {
    // 草稿
    DRAFT: "draft",
    // 審核中
    UNDER_REVIEW: "reviewing",
    // 待審核
    PENDING_REVIEW: "reviewable",
    // 已通過
    APPROVED: "available",
    // 生效中
    IN_USE: "active",
    // 已失效
    ENDED: "ended",
}

if (!doesSupportReview) {
    delete ASSETS_STATE_JOURNEY.UNDER_REVIEW
    delete ASSETS_STATE_JOURNEY.PENDING_REVIEW
}

export { ASSETS_STATE_JOURNEY }

// 劇本中文：草稿、審核中、待審核、已通過、生效中、已失效
let ASSETS_STATE_JOURNEY_CHINESE = {
    // 草稿
    [ASSETS_STATE_JOURNEY.DRAFT]: "草稿",
    // 審核中
    [ASSETS_STATE_JOURNEY.UNDER_REVIEW]: "審核中",
    // 待審核
    [ASSETS_STATE_JOURNEY.PENDING_REVIEW]: "待審核",
    // 已通過
    [ASSETS_STATE_JOURNEY.APPROVED]: doesSupportReview ? "已通過" : "已發布",
    // 生效中
    [ASSETS_STATE_JOURNEY.IN_USE]: "生效中",
    // 已失效
    [ASSETS_STATE_JOURNEY.ENDED]: "已失效",
}

if (!doesSupportReview) {
    delete ASSETS_STATE_JOURNEY_CHINESE[ASSETS_STATE_JOURNEY.UNDER_REVIEW]
    delete ASSETS_STATE_JOURNEY_CHINESE[ASSETS_STATE_JOURNEY.PENDING_REVIEW]
}

export { ASSETS_STATE_JOURNEY_CHINESE }

// 劇本顏色：草稿、審核中、待審核、已通過、生效中、已失效
let ASSETS_STATE_JOURNEY_COLOR = {
    // 草稿
    [ASSETS_STATE_JOURNEY.DRAFT]: "#323232",
    // 審核中
    [ASSETS_STATE_JOURNEY.UNDER_REVIEW]: "#FFCC02",
    // 待審核
    [ASSETS_STATE_JOURNEY.PENDING_REVIEW]: "#F18C29",
    // 已通過
    [ASSETS_STATE_JOURNEY.APPROVED]: "#14B577",
    // 生效中
    [ASSETS_STATE_JOURNEY.IN_USE]: "#409EFF",
    // 已失效
    [ASSETS_STATE_JOURNEY.ENDED]: "#999",
}

if (!doesSupportReview) {
    delete ASSETS_STATE_JOURNEY_COLOR[ASSETS_STATE_JOURNEY.UNDER_REVIEW]
    delete ASSETS_STATE_JOURNEY_COLOR[ASSETS_STATE_JOURNEY.PENDING_REVIEW]
}

export { ASSETS_STATE_JOURNEY_COLOR }

// 劇本素材狀態：草稿、審核中、待審核、已通過、使用中
let ASSETS_STATE_JOURNEY_ASSET = {
    // 草稿
    DRAFT: "draft",
    // 待審核
    PENDING_REVIEW: "reviewable",
    // 審核中
    UNDER_REVIEW: "reviewing",
    // 已通過
    APPROVED: "available",
    // 使用中
    IN_USE: "in_use",
    // 封存
    ARCHIVED: "archived",
}

if (!doesSupportReview) {
    delete ASSETS_STATE_JOURNEY_ASSET.UNDER_REVIEW
    delete ASSETS_STATE_JOURNEY_ASSET.PENDING_REVIEW
}

export { ASSETS_STATE_JOURNEY_ASSET }

// 劇本素材中文：草稿、審核中、待審核、已通過、使用中
let ASSETS_STATE_JOURNEY_ASSET_CHINESE = {
    // 草稿
    [ASSETS_STATE_JOURNEY_ASSET.DRAFT]: "草稿",
    // 審核中
    [ASSETS_STATE_JOURNEY_ASSET.UNDER_REVIEW]: "審核中",
    // 待審核
    [ASSETS_STATE_JOURNEY_ASSET.PENDING_REVIEW]: "待審核",
    // 已通過
    [ASSETS_STATE_JOURNEY_ASSET.APPROVED]: doesSupportReview
        ? "已通過"
        : "已發布",
    // 使用中
    [ASSETS_STATE_JOURNEY_ASSET.IN_USE]: "使用中",
}

export { ASSETS_STATE_JOURNEY_ASSET_CHINESE }

// 劇本素材顏色：草稿、審核中、待審核、已通過、使用中
let ASSETS_STATE_JOURNEY_ASSET_COLOR = {
    // 草稿
    [ASSETS_STATE_JOURNEY_ASSET.DRAFT]: "#323232",
    // 審核中
    [ASSETS_STATE_JOURNEY_ASSET.UNDER_REVIEW]: "#FFCC02",
    // 待審核
    [ASSETS_STATE_JOURNEY_ASSET.PENDING_REVIEW]: "#F18C29",
    // 已通過
    [ASSETS_STATE_JOURNEY_ASSET.APPROVED]: "#14B577",
    // 使用中
    [ASSETS_STATE_JOURNEY_ASSET.IN_USE]: "#409EFF",
}

export { ASSETS_STATE_JOURNEY_ASSET_COLOR }

// 素材審核狀態：第一階段特殊審核前、第一階段特殊審核、第二階段特殊審核前、第二階段特殊審核
export const ASSETS_STATE_JOURNEY_ASSET_REVIEW_STAGE = {
    BEFORE_STAGE_1: "before-stage1",
    BEFORE_STAGE_2: "before-stage2",
    END: "done",
}

// 特殊條件：固定受眾、指定標籤、行銷活動回應、行銷活動日期
export const SPECIAL_CONDITION_TYPE = {
    AUDIENCE: "audience",
    TAG: "tag",
    CAMPAIGN: "campaign",
    CAMPAIGN_DATE: "campaign_date",
}

// 特殊條件：固定受眾、指定標籤、行銷活動回應、行銷活動日期
export const SPECIAL_CONDITION_TYPE_CHINESE = {
    [SPECIAL_CONDITION_TYPE.AUDIENCE]: "固定受眾",
    [SPECIAL_CONDITION_TYPE.TAG]: "指定標籤",
    [SPECIAL_CONDITION_TYPE.CAMPAIGN]: "行銷活動回應",
    [SPECIAL_CONDITION_TYPE.CAMPAIGN_DATE]: "行銷活動日期",
}

// 行銷活動回應選項：點擊、開信或曝光、寄達、活動名單
export const SPECIAL_CONDITION_CAMPAIGN_OPTION = {
    IS_CLICKED: "is_clicked",
    IS_OPENED: "is_opened",
    IS_SENT: "is_sent",
    ALL: "all",
}

// 行銷活動回應選項：點擊、開信或曝光、寄達、活動名單
export const SPECIAL_CONDITION_CAMPAIGN_OPTION_CHINESE = {
    [SPECIAL_CONDITION_CAMPAIGN_OPTION.IS_CLICKED]: "點擊",
    [SPECIAL_CONDITION_CAMPAIGN_OPTION.IS_OPENED]: "開信或曝光",
    [SPECIAL_CONDITION_CAMPAIGN_OPTION.IS_SENT]: "寄達",
    [SPECIAL_CONDITION_CAMPAIGN_OPTION.ALL]: "活動名單",
}

// 受眾狀態：草稿、計算中、可使用、使用中、已停用、封存
export const ASSETS_STATE_AUDIENCE = {
    DRAFT: "draft",
    CALCULATING: "calculating",
    AVAILABLE: "available",
    IN_USE: "in_use",
    DISABLE: "disabled",
    ARCHIVED: "archived",
}

// 受眾狀態中文：草稿、計算中、可使用、使用中、已停用
export const ASSETS_STATE_AUDIENCE_CHINESE = {
    [ASSETS_STATE_AUDIENCE.DRAFT]: "草稿",
    [ASSETS_STATE_AUDIENCE.CALCULATING]: "計算中",
    [ASSETS_STATE_AUDIENCE.AVAILABLE]: "可使用",
    [ASSETS_STATE_AUDIENCE.IN_USE]: "使用中",
    [ASSETS_STATE_AUDIENCE.DISABLE]: "已停用",
}

// 受眾狀態中文：草稿、計算中、可使用、使用中、已停用
export const ASSETS_STATE_AUDIENCE_COLOR = {
    [ASSETS_STATE_AUDIENCE.DRAFT]: "#4F4F4F",
    [ASSETS_STATE_AUDIENCE.CALCULATING]: "#FAC400",
    [ASSETS_STATE_AUDIENCE.AVAILABLE]: "#52C41A",
    [ASSETS_STATE_AUDIENCE.IN_USE]: "#3785FA",
    [ASSETS_STATE_AUDIENCE.DISABLE]: "#FF4747",
}

// 受眾類型中文：固定受眾、條件受眾
export const ASSETS_AUDIENCE_TYPE = {
    MANUAL: "manual",
    RULE: "rule",
}

export const ASSIGNER_TYPE_CHINESE = {
    [ASSETS_AUDIENCE_TYPE.MANUAL]: "固定貼標",
    [ASSETS_AUDIENCE_TYPE.RULE]: "條件貼標",
}

// 受眾類型中文：固定受眾、條件受眾
export const ASSETS_AUDIENCE_TYPE_CHINESE = {
    [ASSETS_AUDIENCE_TYPE.MANUAL]: "固定受眾",
    [ASSETS_AUDIENCE_TYPE.RULE]: "條件受眾",
}

// 受眾類型背景顏色：固定受眾、條件受眾
export const ASSETS_AUDIENCE_TYPE_COLOR = {
    [ASSETS_AUDIENCE_TYPE.MANUAL]: "#E6F7FF",
    [ASSETS_AUDIENCE_TYPE.RULE]: "#FFFAE6",
}

// 受眾類型邊線顏色：固定受眾、條件受眾
export const ASSETS_AUDIENCE_TYPE_BORDER_COLOR = {
    [ASSETS_AUDIENCE_TYPE.MANUAL]: "#1890FF1A",
    [ASSETS_AUDIENCE_TYPE.RULE]: "#FFCC021A",
}

// 用戶狀態：停用、啟用、未啟用
export const ASSETS_STATE_ACCOUNT = {
    ACTIVE: "active", // 啟用
    INACTIVE: "inactive", // 停用
    INITIAL: "initial", // 未啟用
}

// 用戶狀態中文：停用、啟用、未啟用 中文
export const ASSETS_STATE_ACCOUNT_CHINESE = {
    [ASSETS_STATE_ACCOUNT.ACTIVE]: "啟用",
    [ASSETS_STATE_ACCOUNT.INACTIVE]: "停用",
    [ASSETS_STATE_ACCOUNT.INITIAL]: "未啟用",
}

// 用戶狀態顏色：停用、啟用、未啟用 中文
export const ASSETS_STATE_ACCOUNT_COLOR = {
    [ASSETS_STATE_ACCOUNT.ACTIVE]: "#14B577",
    [ASSETS_STATE_ACCOUNT.INACTIVE]: "#E3505C",
    [ASSETS_STATE_ACCOUNT.INITIAL]: "#A8A8A8",
}

// 連結選項：外開連結、內開連結
export const LINK_OPTIONS = {
    IN_APP: "in_app",
    INWARD: "inward",
    OUTWARD: "outward",
}

// 連結選項中文：外開連結、內開連結
export const LINK_OPTIONS_CHINESE = {
    [LINK_OPTIONS.IN_APP]: "APP指定位置",
    [LINK_OPTIONS.OUTWARD]: "外開連結",
    [LINK_OPTIONS.INWARD]: "內開連結",
}

// 受眾分群類型：自由數量分群、自由比例分群
export const AUDIENCE_SPLIT_TYPE = {
    COUNT: "count",
    RATIO: "ratio",
}

// 受眾分群類型中文：自由數量分群、自由比例分群
export const AUDIENCE_SPLIT_TYPE_CHINESE = {
    [AUDIENCE_SPLIT_TYPE.COUNT]: "自由數量分群",
    [AUDIENCE_SPLIT_TYPE.RATIO]: "自由比例分群",
}

// 標籤狀態：可使用（draft）、使用中（in_use）、已停用（disabled）、已封存（archived），default: draft
export const TAGS_STATUS = {
    DRAFT: "draft",
    IN_USE: "in_use",
    DISABLED: "disabled",
    ARCHIVED: "archived",
}

// 貼標狀態：草稿（draft）、計算中（processing）、啟用中（enabled）、貼標完成（done）、已停用（disabled）、封存（archived），default: draft
export const ATTACHED_TAGS_STATUS = {
    DRAFT: "draft",
    PROCESSING: "processing",
    IN_USE: "in_use",
    DISABLED: "disabled",
    ARCHIVED: "archived",
}

// 篩選貼標類型：固定貼標、條件貼標
export const ASSIGNER_TYPE = {
    MANUAL: "manual",
    RULE: "rule",
}

// 篩選貼標邊線顏色：固定貼標、條件貼標
export const ASSIGNER_TYPE_BORDER_COLOR = {
    [ASSIGNER_TYPE.MANUAL]: "#150F961A",
    [ASSIGNER_TYPE.RULE]: "#FFCC021A",
}

// 上傳檔案狀態：完成、失敗、上傳中
export const UPLOAD_FILE_STATE = {
    SUCCESS: "success",
    FAIL: "failed",
    PROCESSING: "processing",
}

// 上傳檔案狀態中文：完成、失敗、上傳中
export const UPLOAD_FILE_STATE_CHINESE = {
    [UPLOAD_FILE_STATE.SUCCESS]: "完成",
    [UPLOAD_FILE_STATE.FAIL]: "失敗",
    [UPLOAD_FILE_STATE.PROCESSING]: "上傳中",
}
// 上傳檔案狀態：完成、失敗、上傳中
export const UPLOAD_FILE_STATE_COLOR = {
    [UPLOAD_FILE_STATE.SUCCESS]: "#52C41A",
    [UPLOAD_FILE_STATE.FAIL]: "#FF4D4F",
    [UPLOAD_FILE_STATE.PROCESSING]: "#1890FF",
}

// 上傳檔案類別：會員格式、訂單格式、名單貼標
export const UPLOAD_TYPE_STATE = {
    MEMBER_LIST: "member_list",
    ORDER_LIST: "order_list",
    TAG_LIST: "tag_list",
}

// 上傳檔案類別：會員格式、訂單格式、名單貼標
export const UPLOAD_TYPE_STATE_CHINESE = {
    [UPLOAD_TYPE_STATE.MEMBER_LIST]: "會員格式",
    [UPLOAD_TYPE_STATE.ORDER_LIST]: "訂單格式",
    [UPLOAD_TYPE_STATE.TAG_LIST]: "名單貼標",
}

// 篩選器上傳檔案類別：上傳名單-歸戶碼、上傳名單-手機、上傳名單-郵件
export const FILTER_UPLOAD_TYPE_STATE = {
    MEMBER_ID_LIST: "member_id_list",
    PHONE_LIST: "phone_list",
    EMAIL_LIST: "email_list",
}

// 篩選器上傳檔案類別：上傳名單-歸戶碼、上傳名單-手機、上傳名單-郵件
export const FILTER_UPLOAD_TYPE_STATE_CHINESE = {
    [FILTER_UPLOAD_TYPE_STATE.MEMBER_ID_LIST]: "上傳名單-歸戶碼",
    [FILTER_UPLOAD_TYPE_STATE.PHONE_LIST]: "上傳名單-手機",
    [FILTER_UPLOAD_TYPE_STATE.EMAIL_LIST]: "上傳名單-郵件",
}

// 併買分析維度：全貢獻、指定商品、併買商品
export const ASSOCIATION_DIMENSION = {
    ALL: "0",
    SPECIFY: "1",
    ASSOCIATION: "2",
}

// 併買分析維度中文：全貢獻、指定商品、併買商品
export const ASSOCIATION_DIMENSION_CHINESE = {
    [ASSOCIATION_DIMENSION.ALL]: "全貢獻",
    [ASSOCIATION_DIMENSION.SPECIFY]: "指定商品",
    [ASSOCIATION_DIMENSION.ASSOCIATION]: "併買商品",
}

// 群組分析天數中文：一、二、三、四、五、六、七
export const COHORT_DAYS = ["一", "二", "三", "四", "五", "六", "七"]

// 行銷活動狀態：草稿、待審核、審核中、排程中、寄送中、已取消、已結束、封存
const CAMPAIGN_STATE = {
    // 草稿
    DRAFT: "draft",
    // 待審核
    REVIEWABLE: "reviewable",
    // 審核中
    REVIEWING: "reviewing",
    // 排程中
    SCHEDULED: "available",
    // 寄送中
    SENDING: "sending",
    // 已取消
    CANCELED: "canceled",
    // 已結束
    ENDED: "ended",
    // 封存
    ARCHIVED: "archived",
}

if (!doesSupportReview) {
    delete CAMPAIGN_STATE.REVIEWABLE
    delete CAMPAIGN_STATE.REVIEWING
}

export { CAMPAIGN_STATE }

// 行銷活動 狀態排序：草稿、待審核、審核中、排程中、寄送中、已取消、已結束
const CAMPAIGN_STATE_ORDER = {
    // 草稿
    [CAMPAIGN_STATE.DRAFT]: 1,
    // 待審核
    [CAMPAIGN_STATE.REVIEWABLE]: 2,
    // 審核中
    [CAMPAIGN_STATE.REVIEWING]: 3,
    // 排程中
    [CAMPAIGN_STATE.SCHEDULED]: 4,
    // 寄送中
    [CAMPAIGN_STATE.SENDING]: 5,
    // 已取消
    [CAMPAIGN_STATE.CANCELED]: 6,
    // 已結束
    [CAMPAIGN_STATE.ENDED]: 7,
}

export { CAMPAIGN_STATE_ORDER }

// 行銷活動中文：草稿、待審核、審核中、排程中、寄送中、已取消、已結束
const CAMPAIGN_STATE_CHINESE = {
    // 草稿
    [CAMPAIGN_STATE.DRAFT]: "草稿",
    // 待審核
    [CAMPAIGN_STATE.REVIEWABLE]: "待審核",
    // 審核中
    [CAMPAIGN_STATE.REVIEWING]: "審核中",
    // 排程中
    [CAMPAIGN_STATE.SCHEDULED]: "排程中",
    // 寄送中
    [CAMPAIGN_STATE.SENDING]: "寄送中",
    // 已取消
    [CAMPAIGN_STATE.CANCELED]: "已取消",
    // 已結束
    [CAMPAIGN_STATE.ENDED]: "已結束",
}

export { CAMPAIGN_STATE_CHINESE }

// 行銷活動顏色：草稿、待審核、審核中、排程中、寄送中、已取消、已結束
let CAMPAIGN_STATE_COLOR = {
    // 草稿
    [CAMPAIGN_STATE.DRAFT]: "#4F4F4F",
    // 待審核
    [CAMPAIGN_STATE.REVIEWABLE]: "#FF751A",
    // 審核中
    [CAMPAIGN_STATE.REVIEWING]: "#FAC400",
    // 排程中
    [CAMPAIGN_STATE.SCHEDULED]: "#52C41A",
    // 寄送中
    [CAMPAIGN_STATE.SENDING]: "#317912",
    // 已取消
    [CAMPAIGN_STATE.CANCELED]: "#6D6D6D",
    // 已結束
    [CAMPAIGN_STATE.ENDED]: "#B0B0B0",
}

export { CAMPAIGN_STATE_COLOR }

// 行銷活動顏色：草稿、待審核、審核中、排程中、寄送中、已取消、已結束
let CAMPAIGN_STATE_BG_COLOR = {
    // 草稿
    [CAMPAIGN_STATE.DRAFT]: "#6D6D6D26",
    // 待審核
    [CAMPAIGN_STATE.REVIEWABLE]: "#FF751A0D",
    // 審核中
    [CAMPAIGN_STATE.REVIEWING]: "#FAC4000D",
    // 排程中
    [CAMPAIGN_STATE.SCHEDULED]: "#52C41A0D",
    // 寄送中
    [CAMPAIGN_STATE.SENDING]: "#52C41A26",
    // 已取消
    [CAMPAIGN_STATE.CANCELED]: "#6D6D6D1A",
    // 已結束
    [CAMPAIGN_STATE.ENDED]: "#FAFAFA",
}

export { CAMPAIGN_STATE_BG_COLOR }

export const CAMPAIGN_TYPE = {
    ONCE: "once",
    REPEATING: "repeating",
    CONDITIONAL: "conditional",
}

export const CAMPAIGN_TYPE_CHINESE = {
    [CAMPAIGN_TYPE.ONCE]: "定時-單次活動",
    [CAMPAIGN_TYPE.REPEATING]: "定時-重複活動",
    [CAMPAIGN_TYPE.CONDITIONAL]: "條件式觸發",
}

export const CAMPAIGN_EDIT_MODE = {
    CREATE: "create",
    EDIT: "edit",
    REVIEW: "review",
}

export const CAMPAIGN_EDIT_MODE_CHINESE = {
    [CAMPAIGN_EDIT_MODE.CREATE]: "新增",
    [CAMPAIGN_EDIT_MODE.EDIT]: "編輯",
    [CAMPAIGN_EDIT_MODE.REVIEW]: "審核",
}

export const MEDIA_TYPE = {
    SMS: "sms",
    EDM: "edm",
    APP_NOTIFICATION: "APP notifications",
}

export const MEDIA_TYPE_CHINESE = {
    [MEDIA_TYPE.SMS]: "簡訊",
    [MEDIA_TYPE.EDM]: "EDM",
    [MEDIA_TYPE.APP_NOTIFICATION]: "APP推播",
}

// 行銷活動 sms 傳送狀態：傳送中、傳送成功、傳送失敗
export const CAMPAIGN_SMS_SEND_STATUS = {
    SENDING: "sending",
    SUCCESSFUL: "successful",
    FAILED: "failed",
}

// 行銷活動 sms 傳送狀態中文：傳送中、傳送成功、傳送失敗
export const CAMPAIGN_SMS_SEND_STATUS_CHINESE = {
    [CAMPAIGN_SMS_SEND_STATUS.SENDING]: "傳送中",
    [CAMPAIGN_SMS_SEND_STATUS.SUCCESSFUL]: "傳送成功",
    [CAMPAIGN_SMS_SEND_STATUS.FAILED]: "傳送失敗",
}
