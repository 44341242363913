import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import PERMISSIONS from "@/utils/permissions/index.js"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import useRouterProtect from "@/composables/useRouterProtect/index.js"
import * as membersApi from "@/api/members/api"
import * as uploadsApi from "@/api/uploads/api.js"

export default [
    {
        path: "/data-manage",
        component: DefaultLayout,
        redirect: () => {
            return { name: "data-abnormal-and-history" }
        },
        name: "data-manage",
        meta: {
            isShow: true,
            name: "數據管理",
            index: "1",
            position: "left",
            menuGroup: "data-manage",
        },
        children: [
            // == 會員管理 ==
            {
                path: "", // 會員管理
                name: "member-manage-redirect",
                redirect: () => {
                    return { name: "member-list" }
                },
                meta: createAuthRouteMeta({
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_MEMBER_DATA],
                    subMenuGroup: "會員管理",
                    subMenuGroupIndex: "1",
                    isSubShow: true,
                    subIndex: "1",
                    subGroup: "member-manage",
                    subGroupName: "會員管理",
                    hasSubMenuGroup: true,
                }),
                children: [
                    {
                        path: ":previousRoute/dashboards/:dashboardSlug/charts/:chartSlug/detail", // 圖表詳情
                        name: "member-dashboard-chart-detail",
                        component: () =>
                            import(
                                "@/pages/MemberManage/MemberDashboardChartDetail.vue"
                            ),
                        meta: {
                            requiresAuth: false,
                            isSubShow: false,
                            subGroup: "member-manage",
                        },
                    },
                    {
                        path: "members", // 會員管理列表
                        name: "member-list",
                        component: () =>
                            import("@/pages/MemberManage/MemberList.vue"),
                        meta: createAuthRouteMeta({
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_MEMBER_DATA],
                            isSubShow: true,
                            subIndex: "1-2",
                            hasSubMenuGroup: false,
                            subMenuGroup: "會員列表",
                            subMenuGroupIndex: "1-2",
                            subGroupName: "會員列表",
                            alternativeGroup: "member-manage",
                        }),
                    },
                    {
                        path: "members/:id", // 會員詳情
                        name: "member-detail",
                        component: () =>
                            import("@/pages/MemberManage/MemberDetail.vue"),
                        meta: createAuthRouteMeta({
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_MEMBER_DATA_DETAIL],
                            isSubShow: false,
                            subGroup: "member-manage",
                        }),
                        children: [
                            {
                                path: "basic",
                                name: "member-detail-basic",
                                props: true,
                                beforeEnter: async (to) => {
                                    const [path, response] =
                                        await useRouterProtect({
                                            to,
                                            PERMISSIONS:
                                                PERMISSIONS.VIEW_MEMBER_DATA_DETAIL,
                                            api: (id) =>
                                                membersApi.getMemberTags(id, {
                                                    params: {
                                                        page: 1,
                                                        size: -1,
                                                    },
                                                }),
                                        })

                                    if (path === true) {
                                        to.params.memberTags =
                                            response.data?.results
                                    }
                                    return path
                                },
                                component: () =>
                                    import(
                                        "@/pages/MemberManage/MemberDetailBasic.vue"
                                    ),
                            },
                            {
                                path: "analysis",
                                name: "member-detail-analysis",
                                props: true,
                                component: () =>
                                    import(
                                        "@/pages/MemberManage/MemberDetailAnalysis.vue"
                                    ),
                            },
                            {
                                path: "list",
                                name: "member-detail-list",
                                props: true,
                                component: () =>
                                    import(
                                        "@/pages/MemberManage/MemberDetailList.vue"
                                    ),
                            },
                            {
                                path: "journey",
                                name: "member-detail-journey",
                                props: true,
                                component: () =>
                                    import(
                                        "@/pages/MemberManage/MemberDetailJourney.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
            // == 資料異常與歷程管理 ==
            {
                path: "", // 資料異常與歷程管理
                name: "data-manage-redirect",
                redirect: () => {
                    return { name: "data-abnormal-and-history" }
                },
                meta: createAuthRouteMeta({
                    requiresAuth: true,
                    permissions: [PERMISSIONS.VIEW_DATA_BATCH],
                    subMenuGroup: "數據管理",
                    subMenuGroupIndex: "2",
                    isSubShow: true,
                    subIndex: "2",
                    subGroup: "data-quality-manage",
                    subGroupName: "數據品質管理",
                    hasSubMenuGroup: true,
                }),
                children: [
                    {
                        path: "data-quality-manage", // 資料異常與歷程管理
                        name: "data-quality-manage",
                        redirect: () => {
                            return { name: "data-abnormal-and-history" }
                        },
                        meta: createAuthRouteMeta({
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_DATA_BATCH],
                            isSubShow: true,
                            subIndex: "2-1",
                            hasSubMenuGroup: true,
                            subMenuGroup: "數據品質管理",
                            subMenuGroupIndex: "2-1",
                        }),
                        children: [
                            {
                                path: "data-abnormal-and-history", // 資料異常與歷程管理
                                name: "data-abnormal-and-history",
                                component: () =>
                                    import(
                                        "@/pages/DataManage/DataAbnormalAndHistory.vue"
                                    ),
                                meta: createAuthRouteMeta({
                                    requiresAuth: true,
                                    permissions: [PERMISSIONS.VIEW_DATA_BATCH],
                                    isSubShow: true,
                                    subIndex: "2-1-1",
                                    subGroup: "data-manage",
                                    subGroupName: "資料異常與歷程管理",
                                    subMenuGroupIndex: "2-1-1",
                                }),
                            },
                        ],
                    },
                ],
            },
            // == 上傳中心 ==
            {
                path: "",
                name: "upload",
                redirect: () => {
                    return { name: "upload-list" }
                },
                meta: {
                    isShow: true,
                    requiresAuth: true,
                    permissions: [
                        PERMISSIONS.VIEW_UPLOAD,
                        PERMISSIONS.ADD_UPLOAD,
                    ],
                    isSubShow: true,
                    subIndex: 3,
                    subGroup: "upload",
                    hasSubMenuGroup: true,
                    subMenuGroup: "上傳中心",
                    subMenuGroupIndex: "3",
                },
                children: [
                    // 上傳數據列表
                    {
                        path: "upload-list",
                        name: "upload-list",
                        component: () =>
                            import("@/pages/CommonGroup/UploadListPage.vue"),
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_UPLOAD],
                            isSubShow: true,
                            subGroup: "upload",
                            subIndex: "3-1",
                            subGroupName: "上傳檔案列表",
                            alternativeGroup: "upload",
                        },
                    },
                    // 上傳檔案
                    {
                        path: "upload-file",
                        name: "upload-file",
                        component: () =>
                            import("@/pages/CommonGroup/UploadFilePage.vue"),
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.ADD_UPLOAD],
                            isSubShow: true,
                            subGroup: "upload",
                            subIndex: "3-2",
                            subGroupName: "上傳檔案",
                            alternativeGroup: "upload",
                        },
                    },
                    // 上傳檔案詳細頁
                    {
                        path: ":id",
                        name: "upload-file-detail",
                        component: () =>
                            import(
                                "@/pages/CommonGroup/UploadFileDetailPage.vue"
                            ),
                        meta: {
                            requiresAuth: true,
                            permissions: [PERMISSIONS.VIEW_UPLOAD],
                            isSubShow: false,
                            subGroup: "upload",
                        },
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.VIEW_UPLOAD,
                                api: uploadsApi.getUploadFileInfo,
                            })

                            if (path === true) {
                                to.params.initialUploadFileResponse = response
                            }
                            return path
                        },
                    },
                ],
            },
        ],
    },
]
