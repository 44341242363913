<script setup>
    import { computed, ref } from "vue"

    const props = defineProps({
        items: { type: Array, required: true },
        subitems: { type: Array, required: true },
        currentItem: { type: Object, required: true },
        user: { type: Object, required: true },
    })

    const hasUnreadNotification = ref(false)

    /**
     * @typedef { import("vue").ComputedRef } ComputedRef
     * @typedef {import('vue-router').RouteLocationRaw} RouteLocationRaw
     * @typedef { import('vue').PropOptions } PropOptions
     */

    /* @type PropOptions */
    const { leftItems, rightItems } = useItemGroup(props)

    /**
     * 使用項目分組
     * @param {object} props - 組件的屬性。
     * @returns {object} - 包含左側項目和右側項目的物件。
     * @property {ComputedRef<[]>} leftItems - 左側項目的計算屬性。
     * @property {ComputedRef<[]>} rightItems - 右側項目的計算屬性。
     */
    function useItemGroup(props) {
        const leftItems = computed(() => {
            return props.items.filter((item) => item.meta.position === "left")
        })

        const rightItems = computed(() => {
            return props.items.filter((item) => item.meta.position === "right")
        })

        return { leftItems, rightItems }
    }
</script>

<script>
    export default {
        inheritAttrs: false,
    }
</script>

<template>
    <el-menu
        mode="horizontal"
        :ellipsis="false"
        :default-active="currentItem.meta?.index || ''"
        class="items-end"
    >
        <h2 class="title">股感CDP數據管理平台</h2>
        <el-menu-item
            v-for="(item, index) in leftItems"
            :key="item.meta.index"
            data-test="top-navbar-item"
            :index="item.meta.index"
            @click="
                () =>
                    $router.push({
                        name: subitems?.[index]?.[0]?.name || item.name,
                    })
            "
        >
            {{ item.meta.name }}
        </el-menu-item>
        <div class="flex-grow" />
        <el-menu-item
            v-for="item of rightItems"
            :key="item.meta.index"
            :index="item.meta.index"
            @click="() => $router.push(item.path)"
        >
            <base-svg-icon
                v-if="item.meta.icon"
                color="var(--el-color-primary)"
                class="right-navbar-icon"
                :name="item.meta.icon"
            />
            <span class="right-navbar-title"> {{ item.meta.name }}</span>
        </el-menu-item>
        <div class="logout-popover relative">
            <div
                v-show="hasUnreadNotification"
                class="absolute right-[40%] z-1 w-2 h-2 bg-[#FAAD14] rounded border border-[#ffffff] border-solid"
            ></div>
            <auth-logout-popover
                :user="user"
                @has-unread="(e) => (hasUnreadNotification = e)"
            />
        </div>
    </el-menu>
</template>

<style lang="scss" scoped>
    .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        padding: 17px 16px;
    }

    .flex-grow {
        flex-grow: 1;
    }

    .logout-popover {
        padding: 13px 20px 13px 0;
    }

    .right-navbar-icon {
        margin-right: 4px;
    }

    .right-navbar-title {
        color: var(--el-color-primary);
    }
</style>
