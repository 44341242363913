import { instance } from "../axios"

/**
 * 取得預覽與寄件人管理設定
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getSendingConfiguration(config = {}) {
    return instance.request({
        url: "/systems/sending-configuration/",
        method: "get",
        ...config,
    })
}

/**
 * 更新預覽與寄件人管理設定
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateSendingConfiguration(config = {}) {
    return instance.request({
        url: "/systems/sending-configuration/",
        method: "put",
        ...config,
    })
}
